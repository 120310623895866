<template>
  <div>
    <div class="text-subtitle1 text-center pie-wrapper my-font">{{ datasets[0].label }}</div>
    <DoughnutConfig
        :labels="labels"
        :datasets="datasets"
        :render="render"
    />
  </div>
</template>

<script>

import {defineComponent} from "vue";
import DoughnutConfig from "../../charts-config/DoughnutConfig";
import {mapState} from "vuex";

export default defineComponent({
  name: "doughnutChart",
  components: {DoughnutConfig},
  data() {
    return {
      datasets: [
        {
          label: "Top Asins Sales",
          backgroundColor: [
            "rgb(187, 222, 251)",
            "rgb(206, 147, 216 )",
            "rgb(128, 203, 196)",
            "rgb(189, 189, 189)",
          ],
          data: [],
        },
      ],
      labels: [],
      localTop4Asins: [],
      render: '',
    }
    // options: {
    //   legend: {
    //     display: false,
    //         position: 'bottom'
    //   }
    // },
  },
  computed: {
    ...mapState('marketTracker', ['top4Asin','top4Indicator']),
  },
  watch: {
    top4Indicator() {
      this.setData()
    }
  },
  methods: {
    // TODO: change to action
    setData() {
      let dataArr = []
      let labelsArr = []
      this.localTop4Asins = this.top4Asin
      for (let asin of this.localTop4Asins){
        dataArr.push(asin.sales)
        labelsArr.push(asin.asin)
      }
      this.datasets[0].data = dataArr
      this.labels = labelsArr
      this.render = 'done'
    },
  },
});
</script>

<style>
.pie-wrapper {
  width: 100%;
  height: 100%;
  max-width: 109%;
  max-height: 100%;
}

</style>


