<template>
  <div>
    <!--    TODO : MAKE A FOR LOOP TO RUN OVER THE BTN GROUP-->
    <q-btn-group class="bg-white tabsBorder">
      <q-btn :class="[statusClass === 'all'? 'bg-secondary': '']" label="All" icon="apps"
             @click="setRequestedArr('all')"/>
      <q-btn :class="[statusClass === 'pending'? 'bg-secondary': '']" label="Pending" icon="update"
             @click="setRequestedArr('pending')"/>
      <q-btn :class="[statusClass === 'tracked'? 'bg-secondary': '']" label="Tracked" icon="visibility"
             @click="setRequestedArr('tracked')"/>
      <q-btn :class="[statusClass === 'ignored'? 'bg-secondary': '']" label="Ignored" icon="visibility_off"
             disable/>
    </q-btn-group>
    <q-card class="q-pa-md text-capitalize">
      <p>SORT BY : </p>
      <q-btn-dropdown
          v-for="(filter, key) of filters"
          :key="key"
          class="q-mx-md"
          color="primary"
          :label="filterTitles[key]">
        <q-list>
          <q-item clickable v-close-popup @click="sortBy(filter, false)">
            <q-item-section>
              <q-item-label v-if="filter !== 'brand' && filter !== 'category'">HIGH TO LOW</q-item-label>
              <q-item-label v-if="filter === 'brand' || filter === 'category'">A - Z</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="sortBy(filter, true)">
            <q-item-section>
              <q-item-label v-if="filter !== 'brand' && filter !== 'category'">LOW TO HIGH</q-item-label>
              <q-item-label v-if="filter === 'brand' || filter === 'category'">Z - A</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-card>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "Filters",
  data() {
    return {
      filters: ['price', 'bsr', 'totalReviews', 'brand', 'category'], //TODO : IF YOU MIGHT, PLEASE STUFF THAT SHIT INTO ONE ARRAY OF OBJs.
      filterTitles: ['price', 'bsr', 'reviews', 'brand', 'category'], //TODO: לא אחי ^
      allProductsArr: [],
      statusClass: 'all',
    }
  },
  methods: {
    ...mapMutations('marketTracker', ['SET_TABLE_FILTER_STATE', 'SET_SORT_ORDER', 'SET_SORT_KEY', 'SET_SORT_INDICATOR']),
    setRequestedArr(param) {
      this.statusClass = param
      this.SET_TABLE_FILTER_STATE(param)
    },
    sortBy(key, ascending) {
      if(ascending) this.SET_SORT_INDICATOR('true' + key);
      if(!ascending) this.SET_SORT_INDICATOR('false' + key);
      this.SET_SORT_KEY(key);
      this.SET_SORT_ORDER(ascending);
    },
  }
}
</script>

<style lang="sass" scoped>

</style>
