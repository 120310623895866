import { render } from "./Overview.vue?vue&type=template&id=0bf4b860&scoped=true"
import script from "./Overview.vue?vue&type=script&lang=js"
export * from "./Overview.vue?vue&type=script&lang=js"

import "./Overview.vue?vue&type=style&index=0&id=0bf4b860&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-0bf4b860"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QItemSection,QAvatar,QItemLabel,QSeparator});
