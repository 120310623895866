<template>
  <div class="row justify-between items-center">
    <div class="q-mt-md bg-grey-3 row">
      <div class="q-pt-md text-h6 text-secondary" :class="textClasses">
        Market Name:
      </div>
      <div class="text-capitalize q-pl-sm text-h3 text-primary" :class="textClasses">
        {{ name }}
      </div>
    </div>
    <!--    TODO: move  buttons to new component-->
    <div class="q-mt-md">
      <q-btn
          class="text-capitalize q-mr-md"
          icon-right="list"
          color="secondary"
          text-color="primary"
          label="Select Market"
      >
        <q-menu anchor="bottom left" self="top left">
          <MarketsList/>
        </q-menu>
      </q-btn>
      <q-btn
          class="text-capitalize"
          icon-right="add_circle"
          color="secondary"
          text-color="primary"
          style="width: 210px"
          label="Create New Market"
          to="/create-new-market"
      />
    </div>
  </div>
</template>
<script>

import MarketsList from "../../views/market-tracker/MarketsList";
import {mapActions, mapState} from "vuex";

export default {

  name: "Title",
  components: {MarketsList},
  data() {
    return {
      name: '',
      textClasses: 'text-left text-bold my-font',
      temp: 0
    }
  },

  computed: {
    ...mapState('marketTracker', ['marketsList', 'currentMarketIndex']),
    // name() {
    //   return this.marketsList[this.currentMarketIndex].MarketName
    // }
  },

  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 20)
    },
  },

  created() {
    this.name = JSON.parse(localStorage.getItem('lastName'))
  }

}
</script>

<style scoped>

</style>
