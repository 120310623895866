import { render } from "./MarketsList.vue?vue&type=template&id=276a747c&scoped=true"
import script from "./MarketsList.vue?vue&type=script&lang=js"
export * from "./MarketsList.vue?vue&type=script&lang=js"

import "./MarketsList.vue?vue&type=style&index=0&id=276a747c&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-276a747c"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QItemLabel});qInstall(script, 'directives', {ClosePopup});
