import { render } from "./ProductLineChart.vue?vue&type=template&id=a1f410e4&scoped=true"
import script from "./ProductLineChart.vue?vue&type=script&lang=js"
export * from "./ProductLineChart.vue?vue&type=script&lang=js"

import "./ProductLineChart.vue?vue&type=style&index=0&id=a1f410e4&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-a1f410e4"

export default script
import QSpinnerCube from 'quasar/src/components/spinner/QSpinnerCube.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinnerCube});
