<template>
    <div>
      <div class="text-subtitle2 text-center">Main Sales</div>
      <LineConfig class="line-wrapper" width="10%" :labels="labels" :datasets="datasets"/>
    </div>
</template>

<script>

import {defineComponent} from 'vue';
import LineConfig from '../../charts-config/LineConfig';

export default defineComponent({
  name: 'LineChart',
  components: {LineConfig},
  data() {
    return {
      datasets: [
        {
          label: "Sales",
          borderColor: "rgb(241, 196, 15)",
          fill: false,
          data: [25, 50, 75, 100, 50, 60, 70, 60, 44, 30, 17, 33],
        }, {
          label: "BSR",
          borderColor: "rgb(255, 160, 122)",
          fill: false,
          data: [300, 350, 280, 250, 220, 350, 400, 500, 470, 455, 420, 400],
          // data: [30000, 30050,28000, 25000 ,22000,35000,45000,50000,47000,45500,42000,40000],
        }, {
          label: "Reviews",
          borderColor: "rgb(231, 76, 60)",
          fill: false,
          data: [1, 2, 7, 12, 22, 35, 40, 41, 41, 41, 43, 50],
        },
      ],
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    }
  }
})
</script>

<style scoped>

.line-wrapper {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

</style>