<template>
  <div>
    <div class="q-pt-md q-ma-lg text-h6 text-secondary text-left text-bold my-font"><b>Top Asins:</b></div>
  </div>
  <div class="top5AsinsCard row wrap justify-center items-center content-center">
    <q-card
        v-if="localTop4Asins !== []"
        class="col-5 my-font q-mx-sm q-my-md q-pa-sm shadow-6"
        :class="option1[key]"
        v-for="(asin, key) of localTop4Asins"
        :key="key"
    >
      <div class="row">
        <div class="text-subtitle1 q-mr-sm q-my-none"><b>{{ asin.asin }}</b></div>
        <q-btn flat dense @click="openAmazonLink(asin.asin)" icon="open_in_new" color="primary"/>
      </div>
      <q-separator inset/>
<!--      TODO: create a loop-->
      <div v-if="asin.bsr" class="q-ml-xs text-subtitle2"><b>BSR: </b>{{ addComma(asin.bsr) }}</div>
      <div v-if="!asin.bsr" class="q-ml-xs text-subtitle2"><b>BSR: </b><q-spinner-dots color="black" size="25px"/></div>
      <div v-if="asin.category" class="q-ml-xs text-subtitle2"><b>Category: </b>{{ limitCategoryName(asin.category) }}</div>
      <div v-if="!asin.category" class="q-ml-xs text-subtitle2"><b>Category: </b><q-spinner-dots color="black" size="25px"/></div>
      <div v-if="asin.averageRatings" class="q-ml-xs text-subtitle2"><b>Rating: </b>{{ asin.averageRatings }}</div>
      <div v-if="!asin.averageRatings" class="q-ml-xs text-subtitle2"><b>Rating: </b><q-spinner-dots color="black" size="25px"/></div>
      <div v-if="asin.totalReviews" class="q-ml-xs text-subtitle2"><b>Reviews: </b>{{ addComma(asin.totalReviews) }}</div>
      <div v-if="!asin.totalReviews" class="q-ml-xs text-subtitle2"><b>Reviews: </b><q-spinner-dots color="black" size="25px"/></div>
    </q-card>
<!--    TODO: add loading state and move to a diff component-->
    <q-card
        v-if="localTop4Asins === []"
        class="col-5 my-font q-mx-sm q-my-md q-pa-sm shadow-6"
        :class="option1[key]"
        v-for="(asin, key) of noAsins"
        :key="key"
        style="height: 135px;width: 190px;"
    >
    </q-card>
  </div>
</template>

<script>

import {mapState} from "vuex"

export default {
  name: "AsinsCard",
  data() {
    return {
      localTop4Asins: [],
      noAsins: ['', '', '', ''],
      option1: ['bg-blue-2', 'bg-purple-3', 'bg-teal-3', 'bg-grey-5'],
      option2: ['border1', 'border2', 'border3', 'border4',]
    }
  },
  computed: {
    ...mapState('marketTracker', ['top4Asin']),
    localTop4Asins() {
      return this.top4Asin
    },
  },
  methods: {
    openAmazonLink(asin) {
      const url = 'https://www.amazon.com/dp/' + asin + '?th=1&psc=1';
      window.open(url, '_blank');
    },
    addComma(number) {
      if (number != null) return Number(number).toLocaleString()
    },
    limitCategoryName(name) {
      if (name != null) return name.substring(0, 10) + '...'
    }
  },
};
</script>

<style lang="sass">

.border1
  border-top: solid 4px #31CCEC
  border-bottom: solid 4px #31CCEC

.border2
  border-top: solid 4px #9c27b0
  border-bottom: solid 4px #9c27b0

.border3
  border-top: solid 4px #009688
  border-bottom: solid 4px #009688

.border4
  border-top: solid 4px #616161
  border-bottom: solid 4px #616161
</style>