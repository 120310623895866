import { render } from "./Filters.vue?vue&type=template&id=cb4b0a2c&scoped=true"
import script from "./Filters.vue?vue&type=script&lang=js"
export * from "./Filters.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-cb4b0a2c"

export default script
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnGroup,QBtn,QCard,QBtnDropdown,QList,QItem,QItemSection,QItemLabel});qInstall(script, 'directives', {ClosePopup});
