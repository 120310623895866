<template>
  <!--  TODO: create a loop for overview cards-->
  <q-card class="q-py-xs row my-font">

    <q-card flat class="col">
      <q-item class="q-ml-md">
        <q-item-section avatar class="avatarz">
          <q-avatar size="59px" font-size="55px" color="white" text-color="text-deep-purple-6" :icon="icons[0]"/>
        </q-item-section>

        <q-item-section>
          <q-item-label class="text-subtitle1"> {{ overviewTitles[0] }}</q-item-label>
          <q-item-label class="text-h5 text-deep-purple-6 text-bold"> ${{ revenue }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-card>

    <q-separator class="bg-deep-purple-2" vertical/>

    <q-card flat class="col">
      <q-item class="q-ml-md">
        <q-item-section avatar class="avatarz">
          <q-avatar size="59px" font-size="55px" color="white" text-color="text-deep-purple-6" :icon="icons[1]"/>
        </q-item-section>

        <q-item-section>
          <q-item-label class="text-subtitle1"> {{ overviewTitles[1] }}</q-item-label>
          <q-item-label class="text-h5 text-deep-purple-6 text-bold"> {{ sales }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-card>

    <q-separator class="bg-deep-purple-2" vertical/>

<!--    <q-card flat class="col">-->
<!--      <q-item class="q-ml-md">-->
<!--        <q-item-section avatar class="avatarz">-->
<!--          <q-avatar size="59px" font-size="55px" color="white" text-color="text-deep-purple-6" :icon="icons[2]"/>-->
<!--        </q-item-section>-->

<!--        <q-item-section>-->
<!--          <q-item-label class="text-subtitle1"> {{ overviewTitles[2] }}</q-item-label>-->
<!--          <q-item-label class="text-h5 text-deep-purple-6 text-bold"> {{ overviewDetails[2] }}</q-item-label>-->
<!--        </q-item-section>-->
<!--      </q-item>-->
<!--    </q-card>-->

    <q-separator class="bg-deep-purple-2" vertical/>

    <q-card flat class="col">
      <q-item class="q-ml-md">
        <q-item-section avatar class="avatarz">
          <q-avatar size="59px" font-size="55px" color="white" text-color="text-deep-purple-6" :icon="icons[3]"/>
        </q-item-section>

        <q-item-section>
          <q-item-label class="text-subtitle1"> {{ overviewTitles[3] }}</q-item-label>
          <q-item-label class="text-h5 text-deep-purple-6 text-bold"> {{ avgReviews }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-card>

  </q-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Sales",
  data() {
    return {
      cardClass: 'q-pa-sm q-ma-sm col',
      overviewTitles: ['REVENUE', 'SALES', 'ROI/MARGIN', 'AVERAGE REVIEWS'],
      revenue: '',
      sales: '',
      roiMargin: '',
      avgReviews: '',
      overviewDetails:
          ['',
            '',
            '74.8%/14.2%', ''
          ],
      icons: ['attach_money', 'attach_money', 'swap_vert', 'notes'],
      icons2: ['monetization_on', 'monetization_on', 'swap_vertical_circle', 'trending_up'],
    }
  },
  computed: {
    ...mapState('marketTracker', ['allMarketRevenue', 'allMarketSales', 'allReviews', 'marketData']),
    revenue() {
      return Number(this.allMarketRevenue).toLocaleString()
    },
    sales() {
      return Number(this.allMarketSales).toLocaleString()
    },
    avgReviews() {
      return Number((this.allReviews / this.marketData.length).toFixed(0)).toLocaleString()
    }
  }
}
</script>

<style lang="sass" scoped>

.avatarz
  vertical-align: middle
  width: 70px
  height: 70px
  border-radius: 50%
  border: 6px solid #D1C4E9
  color: #D1C4E9
  margin-right: 5px

</style>
