import { render } from "./AsinsCard.vue?vue&type=template&id=2c398d86"
import script from "./AsinsCard.vue?vue&type=script&lang=js"
export * from "./AsinsCard.vue?vue&type=script&lang=js"

import "./AsinsCard.vue?vue&type=style&index=0&id=2c398d86&lang=sass"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QBtn,QSeparator,QSpinnerDots});
