import { render } from "./Charts.vue?vue&type=template&id=7e86ca3d&scoped=true"
import script from "./Charts.vue?vue&type=script&lang=js"
export * from "./Charts.vue?vue&type=script&lang=js"

import "./Charts.vue?vue&type=style&index=0&id=7e86ca3d&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-7e86ca3d"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QSeparator});
