import { render } from "./Title.vue?vue&type=template&id=fc90286a&scoped=true"
import script from "./Title.vue?vue&type=script&lang=js"
export * from "./Title.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-fc90286a"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QMenu});
