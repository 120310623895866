import { render } from "./ProductInfo.vue?vue&type=template&id=0196c1d9&scoped=true"
import script from "./ProductInfo.vue?vue&type=script&lang=js"
export * from "./ProductInfo.vue?vue&type=script&lang=js"

import "./ProductInfo.vue?vue&type=style&index=0&id=0196c1d9&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-0196c1d9"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QPagination,QSeparator,QBtn,QTooltip});
