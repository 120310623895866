<template>
  <div class="row">
    <div class="q-mb-sm col-12">
      <Filters/>
    </div>
    <div class="q-mb-md col-12">
      <ProductInfo/>
    </div>
  </div>
</template>
<script>

import Filters from "./table-components/Filters";
import ProductInfo from "./table-components/ProductInfo";

export default {
  name: "Table",
  components: {Filters, ProductInfo}
}

</script>

<style scoped>

</style>
