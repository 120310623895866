<template>
  <q-card flat class="row q-py-sm bg-transparent" style="width: fit-content; margin: 0 auto">
    <q-card flat class="q-mr-sm bg-transparent" style="margin-top: 4px">
      <q-pagination
          v-model="page"
          :max="Math.ceil(virtualList.length/ProductsPerPage)"
          :max-pages="6"
          boundary-number
      />
    </q-card>
    <q-separator vertical color="secondary"/>
    <q-card flat class="bg-transparent" style="max-width: 200px">
      <q-btn flat class="q-mx-sm" :class="[ProductsPerPage === 10 ? buttonClass : 'text-primary']" label="10"
             @click="setProductsPerPage(10)">
        <q-tooltip class="bg-secondary text-primary">show 10 products per page</q-tooltip>
      </q-btn>
      <q-btn flat class="q-mx-sm" :class="[ProductsPerPage === 25 ? buttonClass : 'text-primary']" label="25"
             @click="setProductsPerPage(25)">
        <q-tooltip class="bg-secondary text-primary">show 25 products per page</q-tooltip>
      </q-btn>
      <q-btn flat class="q-mx-sm" :class="[ProductsPerPage === 50 ? buttonClass : 'text-primary']" label="50"
             @click="setProductsPerPage(50)">
        <q-tooltip class="bg-secondary text-primary">show 50 products per page</q-tooltip>
      </q-btn>
    </q-card>
  </q-card>

  <q-card
      v-for="(item, key) in displayedList"
      :key="key"
      class="q-my-sm row"
  >
    <div class="col-12">
      <ProductTitle :product="item"/>
    </div>
    <div class="col-12 row">
      <MainDetails :product="item" class="col-4 my-product"/>
      <ProductLineChart :product="item" :render="render" :renderChart="renderChart" class="col-4 my-product"/>
      <SalesDetails :product="item" class="col-4 my-product"/>
    </div>
  </q-card>

<!--      TODO: move pagination + buttons to different component -->
  <q-card flat class="row q-py-sm bg-transparent" style="width: fit-content; margin: 0 auto">
    <q-card flat class="q-mr-sm bg-transparent" style="margin-top: 4px">
      <q-pagination
          v-model="page"
          :max="Math.ceil(virtualList.length/ProductsPerPage)"
          :max-pages="6"
          boundary-number
      />
    </q-card>
  </q-card>
  <!--    TODO: move pagination + buttons to different component -->
</template>

<script>

import SalesDetails from "./product-info/SalesDetails";
import ProductLineChart from "./product-info/ProductLineChart";
import MainDetails from "./product-info/MainDetails";
import ProductTitle from "./product-info/ProductTitle";

import {mapActions, mapGetters, mapMutations, mapState} from "vuex"

export default {
  name: "ProductCards",
  components: {ProductTitle, MainDetails, ProductLineChart, SalesDetails},

  data() {
    return {
      virtualList: [],
      localMarketId: null,
      tempArr: [],
      /*pagination*/
      page: 1,
      ProductsPerPage: 10,
      buttonClass: 'bg-primary text-white',
      buttons: [10, 25, 50, 100],
      temp: null,
      reviewsData: {},
      salesData: {},
      bsrData: {},
      chartDatum: [],
      render: null,
      renderChart: null
    }
  },
  methods: {
    ...mapActions('marketTracker', ['getDataFromDb']),
    ...mapMutations('marketTracker', ['SET_TOP_4_ASIN', 'CLEAR_TOP_4_ASIN', 'SET_TOP_4_INDICATOR']),

    async getSpecificMarketData(mid) {
      await this.getDataFromDb(mid)
      this.virtualList = this.marketData
    },
    setProductsPerPage(number) {
      this.ProductsPerPage = number
    },
    loadLastMarket() {
      this.temp = JSON.parse(localStorage.getItem('lastMid'))
      if (this.temp) this.localMarketId = this.temp;
      this.getSpecificMarketData(this.temp)
          .then(() => {
            this.getTop4Asin()
          })
    },
    //TODO: make this action
    //TODO: BIGGER TODO  FIX sorting, right now i found a way to make
    //TODO: ascending order work, by filter to descending first....
    getTop4Asin() {
      this.tempArr = [...this.trackedProducts(true)]
      // this.sortByParam(this.tempArr, 'totalReviews', true)
      this.sortByParam(this.tempArr, 'totalReviews', false)
      this.tempArr = this.tempArr.slice(0, 4)
      this.CLEAR_TOP_4_ASIN()
      this.SET_TOP_4_ASIN(this.tempArr)
      this.SET_TOP_4_INDICATOR('done')
    },
  },

  watch: {
    tableFilterState(term) {
      if (term === 'all') this.virtualList = this.trackedProducts(term);
      if (term === 'tracked') this.virtualList = this.trackedProducts(true);
      if (term === 'pending') this.virtualList = this.trackedProducts(false);
    },

    sortIndicator() {
      // TODO: FIX sorting, right now i found a way to make ascending order work, by filter to descending first....
      // this.sortByParam(this.virtualList, this.sortKey, this.sortOrder);
      if (!this.sortOrder) this.sortByParam(this.virtualList, this.sortKey, this.sortOrder)
      if (this.sortOrder) {
        this.sortByParam(this.virtualList, this.sortKey, false);
        this.sortByParam(this.virtualList, this.sortKey, true);
      }
    }
  },
  computed: {
    ...mapState('marketTracker', ['marketData', 'marketId', 'tableFilterState', 'sortIndicator', 'sortKey', 'sortOrder']),
    ...mapState('auth', ['user']),
    ...mapGetters('marketTracker', ['trackedProducts', 'sortByParam']),
    displayedList() {
      this.renderChart = Date.now()

      return this.virtualList.slice((this.page - 1) * this.ProductsPerPage,
          (this.page - 1) * this.ProductsPerPage + this.ProductsPerPage)
    },
    localMarketId() {
      if (this.marketId) {
        this.getSpecificMarketData(this.marketId)
        return this.marketId
      }
    },
  },
  created() {
    this.loadLastMarket()
  }
}
</script>

<style lang="sass" scoped>

.my-product
  max-height: 100%
  max-width: 33%

</style>
