<template>
  <div class="" style="max-width: 375px">
    <q-list>
      <q-item
          class="marketBorder"
          clickable
          v-close-popup
          v-for="(market, key) of marketsList"
          :key="key"
          @click="setChosenMarket(market.marketId, key, market.MarketName, market.keywords)">
        <q-item-section>
          <q-item-label class="text-no-wrap text-primary text-bold text-capitalize" style="font-size: 18px">
            {{ market.MarketName }}
          </q-item-label>
          <q-item-label>
            Tracked Products: {{ market.TrackedAsinNumber ? market.TrackedAsinNumber : '' }}
          </q-item-label>
<!--          TODO: check why sometimes suggestion is negative number edit: "fixed" but not sure-->
          <q-item-label>
            New Suggestions: {{ market.AsinNumber ? (market.AsinNumber - market.TrackedAsinNumber) <= 0
              ? 0 : market.AsinNumber - market.TrackedAsinNumber: 0 }}
          </q-item-label>
          <!--          <q-item-label caption>Status</q-item-label>-->
        </q-item-section>

        <q-item-section side>
          <q-item-label caption>Updated: {{ market.updated ? market.updated.split('T')[0] : '' }}
          </q-item-label>
          <q-item-label caption>Created : {{ market.created ? market.created.split('T')[0] : '' }}
          </q-item-label>
        </q-item-section>

      </q-item>
    </q-list>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  computed: {...mapState('marketTracker', ['marketsList', 'marketId','currentMarketIndex'])},
  name: "MarketsList",

  methods: {
    ...mapMutations('marketTracker', ['SET_CHOSEN_MID', 'CLEAR_CHOSEN_MID', 'SET_CHOSEN_MID_INDEX']),
    // TODO: duplicated code here and on MarketTracker.vue

    setChosenMarket(marketId, index,lastName,lastKeywords) {
      if (this.marketId) this.CLEAR_CHOSEN_MID()
      this.SET_CHOSEN_MID(marketId)
      this.SET_CHOSEN_MID_INDEX(index)
      localStorage.setItem('lastMid', JSON.stringify(marketId))
      localStorage.setItem('lastIndex', JSON.stringify(index))
      localStorage.setItem('lastName', JSON.stringify(lastName))
      localStorage.setItem('lastKeywords', JSON.stringify(lastKeywords))
      // todo: not save mid in localstorage when creating new market
      window.location.reload()
    }
  },
}
</script>

<style lang="sass" scoped>
.marketBorder
  border-top: 1.5px solid #D1C4E9
  border-bottom: 1.5px solid #D1C4E9

</style>
