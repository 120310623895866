<template>
  <div class="bg-grey-3 row">
    <div class="q-mb-lg col-12">
      <Title class="q-mx-lg"/>
    </div>

    <div class="q-mb-lg col-12">
      <Keywords class="q-mx-lg"/>
    </div>

    <div class="q-mb-lg col-12">
      <Overview class="q-mx-lg"/>
    </div>

    <div class="q-mb-lg col-12">
      <Charts class="q-mx-lg"/>
    </div>
    <div class="col-12">
      <Table class="q-mx-lg"/>
    </div>

    <!--    TODO: add scroll to top when the user not on the top-->
    <!--    <q-page-sticky position="bottom-right" :offset="[18, 18]">-->
    <!--      <q-btn fab icon="add" color="accent" />-->
    <!--    </q-page-sticky>-->
  </div>

</template>

<script>

import Title from "../../components/market-tracker/Title";
import Overview from "../../components/market-tracker/Overview";
import Charts from "../../components/market-tracker/Charts";
import Table from "../../components/market-tracker/table/Table";
import Keywords from "../../components/market-tracker/table/table-components/Keywords";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Menu",
  components: {Keywords, Charts, Title, Overview, Table},
  data() {
    return {
      localLastMid: null,
      localLastIndex: null
    }
  },
  methods: {
    ...mapActions('marketTracker', ['getUserMarketsList']),
    loadMarketsData() {
      this.getUserMarketsList('allMarkets')
    }
  },
  created() {
    this.loadMarketsData()
  }
}
</script>

<style scoped>


</style>
