<template>
  <div>
    <div class="text-subtitle1 text-center my-font text-bold">Sales Velocity</div>
    <LineConfig
        v-if="loadingData"
        class="line-wrapper"
        width="10%"
        :labels="labels"
        :datasets="datasets"
        :render="render"/>
    <q-spinner-cube
        v-if="!loadingData"
        class="items-baseline q-pb-xl"
        style=" margin-top:2vh; margin-left: 11vw;"
        color="orange"
        size="12em"
    />
  </div>
</template>

<script>

import {defineComponent} from 'vue';
import LineConfig from '../../../../charts-config/LineConfig';
import {mapActions, mapState} from "vuex";

export default defineComponent({
  name: 'LineChart',
  components: {LineConfig},
  props: ['product', 'render','renderChart'],
  data() {
    return {
      datasets: [
        {
          label: "bsr",
          borderColor: "rgb(241, 196, 15)",
          fill: false,
          data: [],
        },
        {
          label: "sales",
          borderColor: "rgb(255, 160, 122)",
          fill: false,
          data: [],
        },
        {
          label: "reviews",
          borderColor: "rgb(231, 76, 60)",
          fill: false,
          data: [],
        },
      ],
      labels: [],
      render: '',
      loadingData: false,
      localChartDatum: [],
      localProductAsin: null,
    }
  },
  watch: {
    renderChart() {
    this.loadingData = false
    this.loadChartData()
  }
  },
  computed: {
    ...mapState('marketTracker', ['marketId']),
  },
  methods: {
    ...mapActions('marketTracker', ['loadDataToChart']),
    async loadChartData() {
      this.localProductAsin = this.product.asin
      this.localChartDatum = []
      if (this.product.tracked) {
        await this.clicking(this.product);
        this.setData()
      }
    },
    async clicking(product) {
      //TODO : loop over market data.
      this.localChartDatum = await this.loadDataToChart(product.asin, this.marketId)
    },
    setData() {
      this.labels = []
      this.datasets.forEach(subject => {
        let tempArr = [];
        let obj = this.localChartDatum.filter(obj => obj.name === subject.label)[0].data
        if (!this.labels.length) {
          for (let key in obj) {
            this.labels.push(key)
          }
        }
        for (let key in obj) {
          if (obj[key] !== null) tempArr.push(obj[key])
          subject.data = [...tempArr];
        }
      })
      this.render = Date.now()
      this.loadingData = true
    },
  },

  created() {
    this.loadChartData()
  }
})

</script>
<style scoped>

.line-wrapper {
  width: 100%;
  height: 85%;
  max-width: 100%;
  max-height: 100%;
}

</style>7