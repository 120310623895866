import { render } from "./MarketTracker.vue?vue&type=template&id=02859720&scoped=true"
import script from "./MarketTracker.vue?vue&type=script&lang=js"
export * from "./MarketTracker.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-02859720"

export default script
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPageSticky,QBtn});
