<template>
  <div class="row no-wrap justify-between">
    <q-card class="col-3 q-mr-md">
      <AsinsCard/>
    </q-card>

    <q-card class="col-3 q-mr-md doughnutWrapper">
      <DoughnutChart/>
    </q-card>
    <!--    <q-card class="col-6 row">-->
    <!--      <div class="col">-->
    <!--        <AsinsCard/>-->
    <!--      </div>-->
    <!--      <q-separator vertical inset/>-->
    <!--      <div class="col doughnutWrapper">-->
    <!--        <DoughnutChart/>-->
    <!--      </div>-->
    <!--    </q-card>-->
    <!--    -->
    <q-card class="col-5 lineWrapper">
      <MainLineChart/>
    </q-card>
  </div>
</template>

<script>

import AsinsCard from "./charts/AsinsCard";
import DoughnutChart from "./charts/DoughnutChart";
import MainLineChart from "./charts/MainLineChart";

export default {
  name: "charts",
  components: {AsinsCard, DoughnutChart, MainLineChart}
}
</script>

<style lang="sass" scoped>

//.doughnutWrapper
//max-width: 20vw
//max-height: 45vh

//.lineWrapper
//max-width: 50vw
//max-height: 45vh

</style>